// App.js
import React, { useState, useEffect } from 'react';
import FileUploader from './FileUploader';

function App () {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showLogin, setShowLogin] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedPassword = localStorage.getItem('password');

    if (storedUsername === 'costperleadteam@gmail.com' && storedPassword === 'teamper2023@@@') {
      setIsLoggedIn(true);
      setShowLogin(false);
    }
  }, []);

  const handleLogin = () => {
    if (username === 'costperleadteam@gmail.com' && password === 'teamper2023@@@') {
      setIsLoggedIn(true);
      setShowLogin(false);

      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
    } else {
      alert('The email or password is incorrect!');
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setShowLogin(true);

    localStorage.removeItem('username');
    localStorage.removeItem('password');
  };

  return (
    <div className="App">
      {showLogin && (
        <div className={`fixed top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 border-2 border-gray-300 shadow-md z-50 w-80`}>
          <label className={`block mb-2 text-left`}>
            Email
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} className={`block w-full p-2 mb-4 border border-gray-300 rounded`} />
          </label>
          <label className={`block mb-2 text-left`}>
            Password
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className={`block w-full p-2 mb-4 border border-gray-300 rounded`} />
          </label>
          <button onClick={handleLogin} className={`bg-blue-500 text-white p-2 px-5 rounded cursor-pointer hover:bg-blue-700 float-right`}>Login</button>
        </div>
      )}

      {isLoggedIn && (
        <div>
          <button onClick={handleLogout} className={`bg-gray-300 text-white p-1 rounded cursor-pointer hover:bg-gray-400 float-right`}>Logout</button>
          <FileUploader />
        </div>
      )}
    </div>
  );
}

export default App;
