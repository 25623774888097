// FileUploader.js
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

import axios from 'axios';
import Pusher from 'pusher-js';

const FileUploader = () => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const pusher = new Pusher('37463bb749e63fced70c', {
      cluster: 'mt1',
      encrypted: true,
    });

    const channel = pusher.subscribe('tracking-percent');

    channel.bind('update-percent', function (response) {
      // console.log(response);
      if (response.data) {
        let data = response.data;
        switch (data.type) {
          case 1:
            if (data.percent) {
              document.getElementById('percent').innerHTML = data.percent + '%';
            }
            break;

          case 2:
            setImageUrl(data.image_path);
            setPageNumber(data.page_number)
            break;

          default:
            alert('Error uploading file!');
            break;
        }
      }
    });

    return () => {
      pusher.unsubscribe('my-channel');
    };
  }, []);

  const [progress, setProgress] = useState(null);
  const [clickCoordinates, setClickCoordinates] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [order1, setOrder1] = useState(1);
  const [order2, setOrder2] = useState(1);

  const [pageNumber, setPageNumber] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleFileChange = (selectedFile) => {

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      axios.post('https://adsconnect.co/api/upload-file', formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });

      // setImageUrl(response.data.image_path);
      // setPageNumber(response.data.page_number)
    } catch (error) {
      // alert('Error uploading file!');
      console.error('Error uploading file:', error);
    }
  };

  const handleImageClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const image = new Image();
    image.src = imageUrl;

    const widthRatio = image.width / rect.width;
    const heightRatio = image.height / rect.height;

    const clickedX = Math.round(x * widthRatio);
    const clickedY = Math.round(y * heightRatio);

    console.log(clickedX, clickedY);
    if (clickedX !== 0 && clickedY !== 0) {
      let isCreate = true;
      for (let i = 0; i < clickCoordinates.length; i++) {
        const item = clickCoordinates[i];
        if (item[0] === 2 && (!item[3] || !item[4])) {
          item[3] = clickedX;
          item[4] = clickedY;
          clickCoordinates[i] = item;
          isCreate = false;
          break;
        }
      }

      if (isCreate) {
        setClickCoordinates((prevCoordinates) => [...prevCoordinates, [2, order2, '', clickedX, clickedY]]);
        setOrder2(order2 + 1);
      } else {
        setClickCoordinates([...clickCoordinates]);
      }
    }
  };

  const changeTitle = (value, index) => {
    let data = clickCoordinates[index];
    data[2] = value;
    clickCoordinates[index] = data;
    setClickCoordinates([...clickCoordinates]);
  }

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      // Gửi tọa độ lên server
      const serverResponse = await axios.post('https://adsconnect.co/api/caculate-point',
        {
          coordinates: clickCoordinates,
          page_number: pageNumber,
          type: isChecked
        },
        {
          responseType: 'arraybuffer',
        });

      const fileURL = URL.createObjectURL(new Blob([serverResponse.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'data.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    } catch (error) {
      console.error('Error submitting coordinates:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const addQuestion = () => {
    setClickCoordinates((prevCoordinates) => [...prevCoordinates, [1, order1, '']]);
    setOrder1(order1 + 1);
    setOrder2(1);
  }

  const deleteCoordinate = async (index) => {
    clickCoordinates.splice(index, 1);
    let order1 = 1;
    let order2 = 1;
    await clickCoordinates.forEach((item) => {
      if (item[0] === 1) {
        item[1] = order1;
        order1++;
        order2 = 1;
      } else {
        item[1] = order2;
        order2++;
      }
    });
    setOrder1(order1);
    setOrder2(order2);

    setClickCoordinates([...clickCoordinates]);
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFileTemplate = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Get all cell values from the first column
        const columnA = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false, blankrows: false })
          .map(row => row[0]) // Extract the first column
          .filter(value => value !== null && value !== undefined && value !== '');

        console.log(columnA);
        const titles = [];
        let order1 = 0;
        let order2 = 0;
        for (let i = 0; i < columnA.length; i++) {
          const content = columnA[i];
          if (content.charAt(0) === '.') {
            order1++;
            titles.push([1, order1, content.substring(1)]);
            order2 = 0;
          } else {
            order2++;
            titles.push([2, order2, content, '', '']);
          }
        }
        setOrder1(order1 + 1);
        setOrder2(order2 + 1);
        setClickCoordinates(titles);
      };
      reader.readAsBinaryString(file);

    }
  };

  const resetPoint = (index) => {
    let data = clickCoordinates[index];
    clickCoordinates[index] = [data[0], data[1], data[2], '', ''];
    setClickCoordinates([...clickCoordinates]);
  };

  return (
    <div className="flex h-screen">
      <div className="flex-1 border-r border-gray-300 p-4">
        <div className="flex items-center">
          <input type="file" accept=".pdf" className="
            block w-3/4 text-sm text-slate-500
            file:mr-4 file:py-2 file:px-4
            file:rounded file:border-0
            file:text-sm file:font-semibold
            file:bg-violet-200 file:text-violet-700
            hover:file:bg-violet-300
          "
            onChange={(e) => handleFileChange(e.target.files[0])}
          />
          <div className="flex w-1/4">
            <input
              type="checkbox"
              id="checked-checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Có viền</label>
          </div>
        </div>
        {progress !== null && (
          <div>
            <span>Processing: </span>
            <span id="percent">0%</span>
          </div>
        )}
        <div>
          {pageNumber && (<p className="text-right">Số page: {pageNumber}</p>)}
        </div>
        {imageUrl && (
          <div>
            <img
              src={imageUrl}
              alt="Uploaded"
              className="max-w-full border border-solid border-black cursor-move hover:opacity-75"
              onClick={handleImageClick}
            />
          </div>
        )}
      </div>
      <div className="flex-1 p-4">
        <input type="file" accept=".xlsx" className="
            block w-3/4 text-sm text-slate-500 mt-3
            file:mr-4 file:py-2 file:px-4
            file:rounded file:border-0
            file:text-sm file:font-semibold
            file:bg-cyan-200 file:text-cyan-700
            hover:file:bg-cyan-300 mb-3
          " onChange={handleFileTemplate} />
        <div className="mb-3">
          {/* <h2 className="text-xl font-bold mb-4">Click Coordinates</h2> */}
          {clickCoordinates.map(([type, order, title, x, y], index) => (
            <div key={index} className="mb-2 flex items-center">
              {type === 2 ? (
                <>
                  {x !== '' && y !== '' ? (
                    <>
                      <div style={{ backgroundColor: 'rgb(84, 214, 44)', width: '8px', height: '8px', borderRadius: '50%', marginRight: '10px' }}></div>
                    </>
                  ) : (
                    <>
                      <div style={{ backgroundColor: 'rgb(255, 72, 66)', width: '8px', height: '8px', borderRadius: '50%', marginRight: '10px' }}></div>
                    </>
                  )}
                  <span className='mr-2'>{order}.</span>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => changeTitle(e.target.value, index)}
                    className="border border-solid border-gray-400 px-1 rounded w-2/5"
                  />
                  <button
                    key={index + 'change'}
                    onClick={() => resetPoint(index)}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-2 rounded mx-2 py-1"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z" />
                    </svg>
                  </button>
                  <button
                    key={index + 'delete'}
                    onClick={() => deleteCoordinate(index)}
                    className="bg-red-500 hover:bg-red-600 text-white px-3 rounded"
                  >x</button>
                </>
              ) : (
                <>
                  <span>Câu {order}: </span>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => changeTitle(e.target.value, index)}
                    className="border border-solid border-gray-400 p-1 rounded w-1/2"
                  />
                  <button
                    key={index + 'delete'}
                    onClick={() => deleteCoordinate(index)}
                    className="bg-red-500 text-white px-3 py-1 rounded mx-2"
                  >x</button>
                </>
              )}
            </div>
          ))}
        </div>
        <button onClick={addQuestion} disabled={submitting} className="bg-blue-500 text-white px-4 py-1 rounded mr-2">
          Thêm câu hỏi
        </button>
        <button onClick={handleSubmit} disabled={submitting} className="bg-green-500 text-white px-4 py-1 rounded">
          {submitting ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default FileUploader;
